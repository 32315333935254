//import cookie from 'react-cookies';
//let cookies = cookie.loadAll();
class Auth {
    
logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
}

isLoggedIn() {
    return !!localStorage.token;
}

// getMerchantName(){
//     if (cookies.name) {
//         return cookies.name;
//     }
//     return null;
// }
// getMerchantLogo(){
//     if (cookies.logo_url) {
//         return cookies.logo_url;
//     }
//     return null;
// }
// getMerchantId(){
//     if (cookies.id) {
//         return cookies.id;
//     }
//     return null;
// }
// getMerchantType(){
//     if (cookies.type) {
//         return cookies.type;
//     }
//     return null;
// }
// getMerchantToken() {
//     if (cookies.token) {
//         return cookies.token;
//     }
//     return null;
// }
// getMerchantPermNames(){
//     if (cookies.permNames) {
//         return cookies.permNames;
//     }
//     return null;
// }
}

export let auth = new Auth();
