// export default PdfLink;
import React from 'react';
import {
  CardBody,
  Input,
  Row,
  Table,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {httpClient} from './../../../tools/HttpClient';
import {auth} from './../../../tools/Auth';
import {appConfig} from './../../../tools/AppConfig';
import Waiting from './../../Waiting/waiting';
import Pagination from './../../Components/Pagination/pagination';
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';

class PdfLink extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      
      //public
      publicError: false
    };
    
  }
  componentDidMount(){
    var url = new URL(window.location);
    let file_link = window.location.search.split("link=").pop();
    let username = url.searchParams.get("username");
    let phone_number = url.searchParams.get("phone_number");
    console.log(file_link)
    fetch(file_link, { method: 'GET', headers: new Headers({}) })
    .then(response => response.arrayBuffer())
    .then(async arrayBuffer => {
        const pdfDoc = await PDFDocument.load(arrayBuffer)
        // Embed the Helvetica font
        let helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        // Get the first page of the document
        const pages = pdfDoc.getPages();
        pages.map((page) => {
          // Get the width and height of the first page
          const { width, height } = page.getSize();
          // Draw a string of text diagonally across the first page
          try {
            page.drawText(`${username} \n ${phone_number}`, {
              x: (width / 2) - 100,
              y: (height / 2) + 150,
              size: 70,
              font: helveticaFont,
              color: rgb(0, 0, 0),
              lineHeight: 70,
              opacity: 0.3,
              rotate: degrees(-45),
            })
          }
          catch(err) {
            page.drawText(`${phone_number}`, {
              x: (width / 2) - 100,
              y: (height / 2) + 150,
              size: 70,
              font: helveticaFont,
              color: rgb(0, 0, 0),
              lineHeight: 70,
              opacity: 0.3,
              rotate: degrees(-45),
            })
          }
        })
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
    
    
        var blob = new Blob([pdfBytes], {type: "application/pdf"});
        window.location = window.URL.createObjectURL(blob)
        // var link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // var fileName = "test file";
        // link.download = fileName;
        // link.click();     
    });
  }
  renderBody =()=>{
    return(
      <div className="sidebar-page-container">
        <Waiting height="300px"/>
      </div>
    );
  }
  render() {
    return (
      <div className="mainDiv">
        {this.state.publicError?
        <div scroll="no" className="mainErrorDiv">
          <Alert color="danger">
            حدث خطأ اثناء الارسال ، اذا استمرت المشكلة برجاء الإتصال بمزود الخدمة.
          </Alert>
        </div>
      :
        this.renderBody()}
      </div>
    );
  }


}

export default PdfLink;