import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import windows_logo from './../../../assets/img/platforms/windows.png';
import mac_logo from './../../../assets/img/platforms/mac.png';
import android_logo from './../../../assets/img/platforms/android.png';
import ios_logo from './../../../assets/img/platforms/ios.png';

import './DownloadPage.scss'
import { appConfig } from '../../../tools/AppConfig';

class DownloadPage extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <p className="app_version">{appConfig.app_version()}</p>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <span className="clearfix text-center">
                <h1>Platcourse </h1>
                <br/><br/>
                <h4>للاستمتاع بخدماتنا يرجي تحميل التطبيق.</h4>
                <div className="apps_div">
                  <ul>
                    {/* <li>
                      <a href="https://www.mediafire.com/file/c0a5vbf2mzh25pg/platcourse-2.0.1-setup.exe/file" target="_blank">
                        <img src={windows_logo} alt="windows logo" />
                        <br/>
                        <h4>Windows</h4>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#" target="_blank">
                        <img src={mac_logo} alt="mac os logo" />
                        <br/>
                        <h4>Mac OS</h4>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.platcourse.platcourseapplication" target="_blank">
                        <img src={android_logo} alt="andriod logo" />
                        <br/>
                        <h4>Android</h4>
                      </a>
                    </li>
                    <li>
                      <a href="https://apps.apple.com/us/app/platcourse/id1550478737" target="_blank">
                        <img src={ios_logo} alt="ios logo" />
                        <br/>
                        <h4>IOS</h4>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DownloadPage;
