import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import cookie from 'react-cookies';
import DownloadPage from './views/Pages/DownloadPage/DownloadPage';
import UpdateAndroid from './views/Pages/UpdateAndroid/UpdateAndroid';
import TermsPage from './views/Pages/Terms/Terms';
import TermsIOSPage from './views/Pages/Terms/TermsIOS';
import PdfLink from './views/Pages/PdfLink/PdfLink';

import './App.scss';
import { appConfig } from './tools/AppConfig';
import { httpClient } from './tools/HttpClient';
import Waiting from './views/Waiting/waiting';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const RegisterFacebook = React.lazy(() => import('./views/Pages/RegisterFacebook/RegisterFacebook'));
const ForgetPassword = React.lazy(() => import('./views/Pages/ForgetPassword/ForgetPassword'));
const IOSAnnouncement = React.lazy(() => import('./views/Pages/IOSAnnouncement/IOSAnnouncement'));
const IOSQuiz = React.lazy(() => import('./views/Pages/IOSQuiz/IOSQuiz'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      version_waiting: true,
      android_version: ""
    }
  }
  componentDidMount(){
    this.loadVersions();
    var noContext = document.getElementById('root');

    noContext.addEventListener('contextmenu', e => {
      e.preventDefault();
    });
  }
  loadVersions = () => {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    httpClient.get(
      "/api/auth/app-version",
      config,
      (resp) => {
        this.setState({version_waiting: false, android_version: resp.data.android})
      },
      (error) => {
        this.setState({version_waiting: false, android_version: "0"})
      }
    )
  }
  render() {
    try {
      if(window.androidInterface){
        try{
            window.androidInterface.web_version(appConfig.app_version())
        }catch (e){
            window.androidInterface.web_version(appConfig.app_version())
        }
      }else if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.web_version){
          window.webkit.messageHandlers.web_version.postMessage(JSON.stringify({
              web_version: appConfig.app_version()
          }))
      }
    }
    catch(err) {
      
    }
    try {
      cookie.save('plat-course', true, { path: '/' });
    }
    catch(err) {
      
    }
    
    //if((navigator.userAgent.search("Electron") !== -1) || window.androidInterface || /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)){
    if( window.androidInterface || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openVideo)){
      if(window.androidInterface){
        if(this.state.version_waiting){
          return(
            <Waiting height="200px" />
          )
        }else{
          let last_version = false;
          try {
            if(window.androidInterface && window.androidInterface.get_android_version() && window.androidInterface.get_android_version().toString() == this.state.android_version.toString()){
              last_version = true
            }
          }
          catch(err) {
            last_version = false
          }
          if(last_version){
            return (
              <BrowserRouter>
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      <Route exact path="/login/:id?" name="Login Page" render={props => <Login {...props}/>} />
                      <Route exact path="/register/:id?" name="Register Page" render={props => <Register {...props}/>} />
                      <Route exact path="/RegisterFacebook" name="RegisterFacebook Page" render={props => <RegisterFacebook {...props}/>} />
                      <Route exact path="/forgetPassword" name="Forget Password" render={props => <ForgetPassword {...props}/>} />
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <Route exact path="/ios/announcement/:id?/:token?" name="IOSAnnouncement" render={props => <IOSAnnouncement {...props}/>} />
                      <Route exact path="/ios/quiz/:courseId/:quizId/:token?" name="IOSQuiz" render={props => <IOSQuiz {...props}/>} />
                      <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                    </Switch>
                  </React.Suspense>
              </BrowserRouter>
            );
          }else{
            return (
              <BrowserRouter>
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      <Route exact path="/terms" name="terms" render={props => <TermsPage {...props}/>} />
                      <Route path="/" name="UpdateAndroid" render={props => <UpdateAndroid {...props}/>} />
                    </Switch>
                  </React.Suspense>
              </BrowserRouter>
            );
          }
        }
      }else{
        return (
          <BrowserRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/pdf_file" name="PdfLink" render={props => <PdfLink {...props}/>} />
                  <Route exact path="/login/:id?" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/register/:id?" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/RegisterFacebook" name="RegisterFacebook Page" render={props => <RegisterFacebook {...props}/>} />
                  <Route exact path="/forgetPassword" name="Forget Password" render={props => <ForgetPassword {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route exact path="/ios/announcement/:id?/:token?" name="IOSAnnouncement" render={props => <IOSAnnouncement {...props}/>} />
                  <Route exact path="/ios/quiz/:courseId/:quizId/:token?" name="IOSQuiz" render={props => <IOSQuiz {...props}/>} />
                  <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                </Switch>
              </React.Suspense>
          </BrowserRouter>
        );
      }
    }else{
      // return <DownloadPage />
      return (
        <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/terms" name="terms" render={props => <TermsIOSPage {...props}/>} />
                <Route exact path="/terms_ios" name="termsIOS" render={props => <TermsIOSPage {...props}/>} />
                <Route exact path="/pdf_file" name="PdfLink" render={props => <PdfLink {...props}/>} />
                <Route exact path="/ios/announcement/:id?/:token?" name="IOSAnnouncement" render={props => <IOSAnnouncement {...props}/>} />
                <Route exact path="/ios/quiz/:courseId/:quizId/:token?" name="IOSQuiz" render={props => <IOSQuiz {...props}/>} />
                <Route path="/" name="Download" render={props => <DownloadPage {...props}/>} />
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );
    }
    
  }
}

export default App;
