class AppConfig {

    getServerUrl() {
        return "https://platcourse.com";
    }
    app_version(){
        return "3.2.5"
    }
}

export let appConfig = new AppConfig();
