import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import windows_logo from './../../../assets/img/platforms/windows.png';
import mac_logo from './../../../assets/img/platforms/mac.png';
import android_logo from './../../../assets/img/platforms/android.png';
import ios_logo from './../../../assets/img/platforms/ios.png';

import './UpdateAndroid.scss'
import { appConfig } from '../../../tools/AppConfig';

class UpdateAndroid extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <p className="app_version">{appConfig.app_version()}</p>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <span className="clearfix text-center">
                <h1>Platcourse </h1>
                <br/><br/>
                <h4>يرجي تحديث التطبيق للاستمتاع بخدماتنا.</h4>
                <div className="apps_div">
                  <ul>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.platcourse.platcourseapplication" target="_blank">
                        <img src={android_logo} alt="andriod logo" />
                        <br/>
                        <h4>Android</h4>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UpdateAndroid;
