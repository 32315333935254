import React from 'react';
import { StageSpinner } from "react-spinners-kit";
import './waiting.css';

const waiting = (props) => {
    return (
        <div className="waitingComponent" style={{height: props.height}}>
            <StageSpinner size={90} color="#2A3F54" />
        </div>        
    );
}

export default waiting;