import React from 'react';
import {
  CardBody,
  Input,
  Row,
  Table,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {httpClient} from './../../../tools/HttpClient';
import {appConfig} from './../../../tools/AppConfig';
import Waiting from './../../Waiting/waiting';

import ReactPaginate from 'react-paginate';
import './pagination.scss';

class Pagination extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      //pagination
    };
    
  }
  render() {
    return (
      this.props.totalLength !== 0 &&
      <div className="pagination">
        <ReactPaginate
            pageCount={Math.ceil(this.props.totalLength/this.props.pageSize)}
            marginPagesDisplayed={2}
            onPageChange={this.props.handlePaginationChange}
            forcePage={this.props.pageNumber}
            containerClassName="styled-pagination"
            nextLabel="التالي"
            previousLabel="السابق"
        />
      </div>
    );
  }


}

export default Pagination;