// import React from 'react';
// import {
//   CardBody,
//   Input,
//   Row,
//   Table,
//   Alert,
// } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import {httpClient} from './../../../tools/HttpClient';
// import {auth} from './../../../tools/Auth';
// import {appConfig} from './../../../tools/AppConfig';
// import Waiting from './../../Waiting/waiting';
// import Pagination from './../../Components/Pagination/pagination';

// class Terms extends React.Component {
//   constructor(props){
//     super(props);
//     this.state = {
      
//       //public
//       publicError: false
//     };
    
//   }
//   componentDidMount(){
    
//   }
//   renderBody =()=>{
//     return(
//       <div className="sidebar-page-container">
//         <div className="auto-container">
//           <div className="row clearfix">
//             <div className="content-side col-lg-12 col-md-12 col-sm-12">
//               <div className="sec-title">
//                 <h4>الشروط و الاحكام</h4>
//               </div>
//               <div className="contact-info-section">
//                 <h6>من نحن/المهمة</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">Platcourse هي منصة الكترونية هدفها تنمية المهارات الفكرية و العملية من خلال التعلم. نقوم بتمكين أي شخص في أي مكان من إنشاء ومشاركة فيديوهات و ملفات الكورسات التعليمية (المدربين) والتسجيل في هذه الكورسات التعليمية لتعلم (الطلاب). </div>
//                   </div>
//                 </div>
//                 <h6>الشروط و الاحكام</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">تحتفظ منصة Platcourse التعليمية بالحق في مراجعة الشروط و الاحكام وفقًا لتقديرها الخاص في أي وقت عن طريق نشر التغييرات على المنصة. تصبح التغييرات سارية بعد ثلاثين (30) يومًا من النشر. يعني استمرار استخدامك للمنتجات بعد التغيير سريانًا قبولك لهذه التغييرات. يجب عليك زيارة الموقع بانتظام للتأكد من أنك على دراية بأحدث نسخة من الشروط.</div>
//                   </div>
//                 </div>
//                 <h6>تعليمات الاستخدام</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">نحتاج إلى قواعد للحفاظ على نظامنا الأساسي وخدماتنا آمنة لك ولنا وللمجتمع الطلابي والمدرب لدينا. تنطبق هذه الشروط على جميع أنشطتك على Platcourse والخدمات الأخرى ذات الصلة .</div>
//                     <div className="text">عندما تقوم بالتسجيل في كورس تدريبية ، فإنك تحصل على ترخيص منا لمشاهدته عبر  Platcourse طوال المدة المتاحة للكورس و التى تختلف من دور لأخرى و من مدرب لأخر.</div>
//                     <div className="text">لا تحاول نقل أو إعادة بيع الكورسات بأي طريقة (بما في ذلك مشاركة معلومات الحساب مع شخص أخر أو تنزيل الكورس التدريبية بطريقة غير قانونية ومشاركتها على مواقع الانترنت او اى تطبيقات أخرى)
// لا تقوم بمشاركة حسابك و كلمة السر مع أى شخص و حاول دائما ان تكون متصل بالموقع او التطبيق logged in  , حيث أن القيام بالخروج و الدخول الى الموقع او التطبيق أكثر من مرة شهريا يمكن أن يتم التعامل معه من الموقع او التطبيق على انه تلاعب و مشاركة للحساب و يتم حظر الحساب.</div>
//                     <div className="text">لا يجوز لك إعادة إنتاج أو إعادة توزيع أو نقل أو بيع أو بث أو مشاركة أو تعديل أو تحرير أو إنشاء أعمال مشتقة من أي من الأقسام الفرعية أو بأي طريقة أخرى نقل أو استخدام أي كورس تدريبية ما لم نمنحك إذنًا صريحًا بالقيام بذلك في اتفاقية مكتوبة موقعة من قبل ممثل مفوض Platcourse ينطبق هذا أيضًا على المحتوى الذي يمكنك الوصول إليه عبر أي من واجهات برمجة التطبيقات الخاصة بنا أو عبر القرصنة و الطرق الغير مشروعة.</div>
//                     <div className="text">إذا كنت مدرب/مدرس/محاضر و تود نشر أى كورسات على Platcourse ، فيجب عليك أيضًا الموافقة و التوقيع على اتفاقية المدرب.</div>
//                     <div className="text">لا يجوز للمدربين منح تراخيص لكورساتهم الدراسية للطلاب بشكل مباشر ، ويكون أي ترخيص مباشرلا يتم عن طريق Platcourse لاغياً وباطلاً وينتهك هذه الشروط.</div>
//                     <div className="text">أنت توافق على أنه من خلال تسجيل خدماتنا أو الوصول إليها أو استخدامها ، فإنك توافق على إبرام عقد ملزم قانونًا مع Platcourse إذا كنت لا توافق على هذه الشروط ، فلا تسجل أو تدخل أو تستخدم أيًا من خدماتنا.
// اشتراكك و عدم التزامك بأى من الشروط او الاحكام يعرضك لحظر الحساب و المسائلة القانونية.</div>
//                   </div>
//                 </div>
//                 <h6>التزامات المدرب</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">بصفتك مدربًا ، فأنت مسؤول عن كل المحتوى الذي .
// أنت تقر وتضمن ما يلي:</div>
//                     <div className="text">أنت تفوض Platcourse لاستخدام المحتوى المقدم كما هو محدد في هذه الشروط وشروط الاستخدام ؛
// لن ينتهك المحتوى المقدم أو ينتهك حقوق الملكية الفكرية لأي طرف ثالث ؛
// لديك المؤهلات المطلوبة والخبرة (بما في ذلك التعليم والتدريب والمعرفة ومجموعات المهارات) لتدريس وتقديم الخدمات التي تقدمها من خلال المحتوى المقدم واستخدام الخدمات ؛ وسوف تستجيب على الفور للطلاب وتضمن جودة الخدمة و اكتمالها و عدم تأخيرها.</div>
//                     <div className="text">عدم نشر المحتوى على منصات أخرى فى نفس التوقيت بسعر اقل او جودة مختلفة عن المعروض على منصة Platcourse
// يمنع على المدرب نشر أو تقديم أي محتوى أو معلومات غير لائقة أو مسيئة أو عنصرية أو دينية أو جنسية أو نقل أي إعلانات أو مواد ترويجية .</div>
//                   </div>
//                 </div>
//                 <h6>قواعد المحتوى والسلوك</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">المدرب/المدرس/المحاضر/الطالب مسؤول عن كل المحتوى الذي ينشره على Platcourse  يجب عليك الحفاظ على ارشادات الثقة والسلامة الخاصة بنا والقانون ، واحترام حقوق الملكية الفكرية للآخرين.
//  يمكننا حظر حسابك بسبب المخالفات المتكررة أو الرئيسية. </div>
//                     <div className="text">تحتفظ بملكية المحتوى الذي تنشره على نظامنا الأساسي ، بما في ذلك الكورسات التدريبية. يُسمح لنا بمشاركة المحتوى الخاص بك لأي شخص عبر أي وسائط ، بما في ذلك الترويج له عبر الإعلان على مواقع الويب الأخرى.</div>
//                   </div>
//                 </div>
//                 <h6>قواعد التسعير و الدفع</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">يتم تحديد أسعار الكورسات التدريبية على بناءً على شروط اتفاقية المدرب وسياسة التسعير والترويج الخاصة بنا. </div>
//                     <div className="text">أنت توافق على دفع رسوم الكورسات التدريبية التي تشتريها بأحد الطرق التى يتيحها الموقع او التطبيق للدفع و التى قد تتغير من وقت لأخر.</div>
//                     <div className="text">عند إجراء عملية شراء ، فإنك توافق على عدم استخدام طريقة دفع غير صالحة أو غير مصرح بها. إذا فشلت طريقة الدفع الخاصة بك وما زلت تحصل على حق الوصول إلى الكورس التدريبية التي تسجل فيها ، فإنك توافق على دفع الرسوم المقابلة لنا في غضون ثلاثين (30) يومًا من إخطارنا. نحن نحتفظ بالحق في تعطيل الوصول إلى أي كورس لم نتلق لها مدفوعات كافية.</div>
//                     <div className="text">نظرًا لطبيعة المنتج ، فإن القاعدة العامة هي أن Platcourse لا تقدم المبالغ المستردة للكورسات المباعة.</div>
//                     <div className="text">ومع ذلك ، قد تقوم  Platcourse بتمكينك من استعادة أموالك فى حالة حدوث عطل فنى أدى الا عدم تمكنك من مشاهدة الكورس , يجب استلام طلب استرداد الأموال خلال أسبوع كحد أقصى من شراء الكورس التدريبية.</div>
//                     <div className="text">لا يتم استرداد الأموال إلا من خلال طريقة الدفع الأصلية.</div>
//                   </div>
//                 </div>
//                 <h6>حقوق الملكية و الملكية الفكرية</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">نحن نملك موقع Platcourse و جميع التطبيقات المتعلقة به بما في ذلك موقع والخدمات الحالية أو المستقبلية وأشياء مثل شعاراتنا وواجهة برمجة التطبيقات والكود والمحتوى الذي أنشأه موظفونا. لا يمكنك العبث بها أو استخدامها دون إذن.</div>
//                   </div>
//                 </div>
//                 <h6>تنويه</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">قد يحدث تعطل نظامنا الأساسي ، إما للصيانة المخططة أو بسبب حدوث شيء ما في الموقع.</div>
//                     <div className="text">قد يحدث أيضًا أن نواجه مشكلات.</div>
//                     <div className="text">نحن لسنا مسؤولين تجاه الطلاب او المدربين عن تأخير أو فشل أدائنا في أي من الخدمات التي تسببها أحداث خارجة عن سيطرتنا المعقولة ، مثل عمل من أعمال الحرب أو العداء أو التخريب أو القرصنة؛ كارثة طبيعية؛ انقطاع التيار الكهربائي أو الإنترنت أو الاتصالات عن بُعد ؛ أو قيود الحكومة .</div>
//                     <div className="text">.أنت توافق على أنك لن تتمتع بأي لجوء ضدنا في أي من هذه الأنواع من الحالات التي لا تنجح فيها بعض الأمور. </div>
//                   </div>
//                 </div>
//                 <h6>كيف يمكنك الأتصال بنا</h6>
//                 <div className="row clearfix">
//                   <div className="column col-lg-12 col-md-12 col-sm-12">
//                     <div className="text">أفضل طريقة للاتصال بنا هي الاتصال بفريق الدعم أو عن طريق البريد الالكترونى أو عند طريق أحد أرقام هواتفنا.</div>
//                     <div className="text"> يسعدنا سماع أسئلتك واهتماماتك وتعليقاتك حول خدماتنا.</div>
//                   </div>
//                 </div>


//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
//   render() {
//     return (
//       <div className="mainDiv">
//         {this.state.publicError?
//         <div scroll="no" className="mainErrorDiv">
//           <Alert color="danger">
//             حدث خطأ اثناء الارسال ، اذا استمرت المشكلة برجاء الإتصال بمزود الخدمة.
//           </Alert>
//         </div>
//       :
//         this.renderBody()}
//       </div>
//     );
//   }


// }

// export default Terms;
import React from 'react';
import {
  CardBody,
  Input,
  Row,
  Table,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {httpClient} from './../../../tools/HttpClient';
import {auth} from './../../../tools/Auth';
import {appConfig} from './../../../tools/AppConfig';
import Waiting from './../../Waiting/waiting';
import Pagination from './../../Components/Pagination/pagination';

class Terms extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      
      //public
      publicError: false
    };
    
  }
  componentDidMount(){
    
  }
  renderBody =()=>{
    return(
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-lg-12 col-md-12 col-sm-12">
              <div className="sec-title">
                <h4>الشروط و الاحكام</h4>
              </div>
              <div className="contact-info-section">
                <h6>من نحن/المهمة</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">Platcourse هي منصة الكترونية هدفها تنمية المهارات الفكرية و العملية من خلال التعلم. نقوم بتمكين أي شخص في أي مكان من إنشاء ومشاركة فيديوهات وملفات الكورسات التعليمية (المدربين) والتسجيل في هذه الكورسات التعليمية لتعلم (الطلاب). </div>
                  </div>
                </div>
                <h6>الشروط و الاحكام</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">تحتفظ منصة Platcourse التعليمية بالحق في مراجعة الشروط و الاحكام وفقًا لتقديرها الخاص في أي وقت عن طريق نشر التغييرات على المنصة. تصبح التغييرات سارية بعد ثلاثين (30) يومًا من النشر. يعني استمرار استخدامك للمنتجات بعد التغيير سريانًا قبولك لهذه التغييرات. يجب عليك زيارة الموقع بانتظام للتأكد من أنك على دراية بأحدث نسخة من الشروط. </div>
                  </div>
                </div>
                <h6>تعليمات الاستخدام</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">نحتاج إلى قواعد للحفاظ على نظامنا الأساسي وخدماتنا آمنة لك ولنا وللمجتمع الطلابي والمدرب لدينا. تنطبق هذه الشروط على جميع أنشطتك على Platcourse والخدمات الأخرى ذات الصلة .</div>
                    <div className="text">عندما تقوم بالتسجيل في كورس ، فإنك تحصل على ترخيص منا لمشاهدته عبر  Platcourse طوال المدة المتاحة للكورس و التى تختلف من كورس لأخر و من مدرب لأخر.
لا تحاول نقل أو نسخ الكورسات بأي طريقة (بما في ذلك مشاركة معلومات الحساب مع شخص أخر أو تنزيل الكورسات بطريقة غير قانونية ومشاركتها على مواقع الانترنت او اى تطبيقات أخرى)
لا تقوم بمشاركة حسابك و كلمة السر مع أى شخص.</div>
                    <div className="text">لا يجوز لك إعادة إنتاج أو إعادة توزيع أو نقل أو بث أو مشاركة أو تعديل أو تحرير أو إنشاء أعمال مشتقة من أي من الأقسام الفرعية أو بأي طريقة أخرى نقل أو استخدام أي كورس ما لم نمنحك إذنًا صريحًا بالقيام بذلك ,ينطبق هذا أيضًا على المحتوى الذي يمكنك الوصول إليه عبر أي من واجهات برمجة التطبيقات الخاصة بنا أو عبر القرصنة و الطرق الغير مشروعة.</div>
                    <div className="text">إذا كنت مدرب/مدرس/محاضر و تود نشر أى كورسات على Platcourse ، فاشتراكك يعنى موافقتك على الشروط و الاحكام .</div>
                    <div className="text">أنت توافق سواء كنت طالب او مدرس على أنه من خلال تسجيل خدماتنا أو الوصول إليها أو استخدامها ، فإنك توافق على إبرام عقد ملزم قانونًا مع Platcourse إذا كنت لا توافق على هذه الشروط ، فلا تسجل أو تدخل أو تستخدم أيًا من خدماتنا.
اشتراكك و عدم التزامك بأى من الشروط او الاحكام يعرضك لحظر الحساب و المسائلة القانونية.</div>
                  </div>
                </div>
                <h6>التزامات المدرب</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">بصفتك مدربًا ، فأنت مسؤول عن كل المحتوى الذي تقدمه.
أنت تقر وتضمن ما يلي:
أنت تفوض Platcourse لاستخدام المحتوى المقدم كما هو محدد في هذه الشروط وشروط الاستخدام ؛
لن ينتهك المحتوى المقدم أو ينتهك حقوق الملكية الفكرية لأي طرف ثالث ؛
لديك المؤهلات المطلوبة والخبرة (بما في ذلك التعليم والتدريب والمعرفة ومجموعات المهارات) لتدريس وتقديم الخدمات التي تقدمها من خلال المحتوى المقدم واستخدام الخدمات ؛ وسوف تستجيب على الفور للطلاب وتضمن جودة الخدمة و اكتمالها و عدم تأخيرها.
يمنع على المدرب نشر أو تقديم أي محتوى أو معلومات غير لائقة أو مسيئة أو عنصرية أو دينية أو جنسية أو نقل أي إعلانات أو مواد ترويجية .</div>
                  </div>
                </div>
                <h6>قواعد المحتوى والسلوك</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">المدرب/المدرس/المحاضر/الطالب مسؤول عن كل المحتوى الذي ينشره على Platcourse  يجب عليك الحفاظ على ارشادات الثقة والسلامة الخاصة بنا والقانون ، واحترام حقوق الملكية الفكرية للآخرين.
 يمكننا حظر حسابك بسبب المخالفات المتكررة أو الرئيسية. </div>
                    <div className="text">تحتفظ بملكية المحتوى الذي تنشره على نظامنا الأساسي ، بما في ذلك بعض الكورسات. يُسمح لنا بمشاركة المحتوى الخاص بك لأي شخص عبر أي وسائط ، بما في ذلك الترويج له عبر الإعلان على مواقع الويب الأخرى.</div>
                  </div>
                </div>
                <h6>حقوق الملكية و الملكية الفكرية</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">نحن نملك موقع Platcourse و جميع التطبيقات المتعلقة به بما في ذلك موقع والخدمات الحالية أو المستقبلية وأشياء مثل شعاراتنا وواجهة برمجة التطبيقات والكود والمحتوى الذي أنشأه موظفونا. لا يمكنك العبث بها أو استخدامها دون إذن.</div>
                  </div>
                </div>
                <h6>تنويه</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">قد يحدث تعطل نظامنا الأساسي ، إما للصيانة المخططة أو بسبب حدوث شيء ما في الموقع. 
قد يحدث أيضًا أن نواجه مشكلات.
نحن لسنا مسؤولين تجاه الطلاب او المدربين عن تأخير أو فشل أدائنا في أي من الخدمات التي تسببها أحداث خارجة عن سيطرتنا المعقولة ، مثل عمل من أعمال الحرب أو العداء أو التخريب أو القرصنة؛ كارثة طبيعية؛ انقطاع التيار الكهربائي أو الإنترنت أو الاتصالات عن بُعد ؛ أو قيود الحكومة .
.أنت توافق على أنك لن تتمتع بأي لجوء ضدنا في أي من هذه الأنواع من الحالات التي لا تنجح فيها بعض الأمور. </div>
                  </div>
                </div>
                <h6>كيف يمكنك الأتصال بنا</h6>
                <div className="row clearfix">
                  <div className="column col-lg-12 col-md-12 col-sm-12">
                    <div className="text">أفضل طريقة للاتصال بنا هي الاتصال بفريق الدعم أو عن طريق البريد الالكترونى أو عند طريق أحد أرقام هواتفنا.
 يسعدنا سماع أسئلتك واهتماماتك وتعليقاتك حول خدماتنا.</div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="mainDiv">
        {this.state.publicError?
        <div scroll="no" className="mainErrorDiv">
          <Alert color="danger">
            حدث خطأ اثناء الارسال ، اذا استمرت المشكلة برجاء الإتصال بمزود الخدمة.
          </Alert>
        </div>
      :
        this.renderBody()}
      </div>
    );
  }


}

export default Terms;