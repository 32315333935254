import axios from "axios";
import {appConfig} from "./AppConfig";

let current_platform;
if(window.androidInterface){
    current_platform = "android";
}else if(navigator.userAgent.search("Electron") !== -1){
    current_platform = "desktop";
}else{
    current_platform = "web"
}
class HttpClient {

    get(path, config, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        if(config && config.params){
            config.params["platform"] = current_platform
        }else{
            config["params"] = {
                "platform": current_platform
            }
        }
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    getTest(path, config, successCb, errorCb) {
        let url = path;
        config.headers["Cache-Control"]= "no-cache";
        // config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    delete(path, config, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        config.headers["Cache-Control"]= "no-cache";
        // config.timeout= 100 * 1000;
        axios.delete(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    post(path, config, data, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.post(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
    put(path, config, data, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        config.headers["Cache-Control"]= "no-cache";
        // config.timeout= 100 * 1000;
        axios.put(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
}

export let httpClient = new HttpClient();
